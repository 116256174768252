import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"
import TechTag from "../components/TechTag"
// Images
import ServicesImg from "../images/services.svg"
import BackendImg from "../images/services/backend.svg"
import DesignImg from "../images/services/design.svg"
import EcommerceImg from "../images/services/ecommerce.svg"
import FrontendImg from "../images/services/frontend.svg"
import InfrastructureImg from "../images/services/infrastructure.svg"
import IotImg from "../images/services/iot.svg"
import MobileImg from "../images/services/mobile.svg"
import QualityImg from "../images/services/quality.svg"
import ResearchImg from "../images/services/research.svg"
import { useIntl } from "react-intl"

export const ServicesPageTemplate = ({ title, content, contentComponent }) => {
  const intl = useIntl()
  const PageContent = contentComponent || Content
  const services = [
    {
      title: intl.formatMessage({ id: "services.frontendDev" }),
      icon: FrontendImg,
      technologies: [
        "JavaScript",
        "TypeScript",
        "React",
        "Redux",
        "Vue.js",
        "11ty.js",
        "Gatsby.js",
        "Material UI",
        "Bootstrap",
        "Apollo GraphQL",
        "Webpack",
      ],
    },
    {
      title: intl.formatMessage({ id: "services.backendDev" }),
      icon: BackendImg,
      technologies: [
        "Ruby on Rails",
        "Python & Django",
        "Node.js",
        "Blitz.js",
        "Prisma",
        "Hasura",
        "Go",
        "Elixir",
        "PHP",
        "GraphQL",
        "MySQL",
        "PostgreSQL",
        "MongoDB",
        "Redis",
        "ElasticSearch",
        "S3",
      ],
    },
    {
      title: intl.formatMessage({ id: "services.mobileDev" }),
      icon: MobileImg,
      technologies: [
        "React Native",
        "Flutter",
        "iOS",
        "Android",
        "Android TV",
        "Swift",
        "Objective-C",
        "RxSwift",
        "Anchorage",
        "SwiftUI",
        "Java",
        "Kotlin",
        "Couroutines",
        "Firebase",
        "CodePush",
      ],
    },
    {
      title: intl.formatMessage({ id: "services.ecommerce" }),
      icon: EcommerceImg,
      technologies: ["Magento", "Braintree", "Stripe", "Spree"],
    },
    {
      title: intl.formatMessage({ id: "services.iot" }),
      icon: IotImg,
      technologies: ["Home Assistant", "HomeKit", "MQTT", "Zigbee", "LoRa"],
    },
    {
      title: intl.formatMessage({ id: "services.research" }),
      icon: ResearchImg,
      technologies: ["UML", "BPMN", "RAD", "IDEF"],
    },
    {
      title: intl.formatMessage({ id: "services.design" }),
      icon: DesignImg,
      iconClasses: "h-16",
      technologies: ["Sketch", "Figma", "Invision", "Adobe XD", "Zeplin"],
    },
    {
      title: intl.formatMessage({ id: "services.infrastructure" }),
      icon: InfrastructureImg,
      technologies: [
        "Linux",
        "Docker",
        "Kubernetes",
        "Terraform",
        "AWS",
        "Azure",
        "Google Cloud Platform",
        "Digital Ocean",
        "Heroku",
        "Circle CI",
        "GitHub",
      ],
    },
    {
      title: intl.formatMessage({ id: "services.qa" }),
      icon: QualityImg,
      technologies: [
        "RSpec",
        "Jest",
        "Cypress.js",
        "Cucumber",
        "Selenium",
        "Puppeteer",
      ],
    },
  ]

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className="container flex flex-col mx-auto p-10 lg:px-32 lg:py-24 bg-no-repeat bg-hidden lg:bg-80-35"
        style={{ backgroundImage: `url(${ServicesImg})` }}
      >
        <h2 className="font-semibold text-4xl tracking-wide text-gray-900 max-w-xl leading-snug">
          {title}
        </h2>
        <PageContent
          className="font-light text-lg text-gray-500 mt-6 lg:max-w-xl md:max-w-md"
          content={content}
        />
      </div>
      <div className="bg-gray-200" data-sal="slide-up" data-sal-delay="100">
        <div className="container flex flex-col lg:flex-row mx-auto flex-wrap p-10 lg:py-20">
          {services.map((serviceItem, i) => (
            <div
              className="lg:w-1/3 text-center mb-5 lg:mb-16 p-0 lg:px-5"
              key={i}
            >
              <div className="h-24 flex content-center justify-center">
                <img
                  src={serviceItem.icon}
                  alt={serviceItem.title}
                  className={serviceItem.iconClasses}
                />
              </div>
              <h4 className="uppercase my-2 font-semibold">
                {serviceItem.title}
              </h4>
              <div className="flex flex-wrap justify-center">
                {serviceItem.technologies.map((technology, j) => (
                  <TechTag key={j} className="bg-white">
                    {technology}
                  </TechTag>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ServicesPage = ({ data, location }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout location={location}>
      <ServicesPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesPage

export const ServicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
